// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-btn {
  display: flex;
  position: relative;
  z-index: 89;

  bottom: 30px;
  top: 50px;
}
.img1 {
  width: 100%;
  transform: rotate(63deg);
  height: auto;
  top: 100px;
  position: relative;
}

@media (max-width: 1024px) {
  .img1 {
    top: 10px;
  }
}
/* @media (max-width: 500px) {
  .footer-btn {
    display: flex;
    position: absolute;
    z-index: 89;
    right: auto;
    bottom: auto;
  }
  .selec {
    width: auto !important;
    right: 20px !important;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/Choose.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW;;EAEX,YAAY;EACZ,SAAS;AACX;AACA;EACE,WAAW;EACX,wBAAwB;EACxB,YAAY;EACZ,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE;IACE,SAAS;EACX;AACF;AACA;;;;;;;;;;;;GAYG","sourcesContent":[".footer-btn {\r\n  display: flex;\r\n  position: relative;\r\n  z-index: 89;\r\n\r\n  bottom: 30px;\r\n  top: 50px;\r\n}\r\n.img1 {\r\n  width: 100%;\r\n  transform: rotate(63deg);\r\n  height: auto;\r\n  top: 100px;\r\n  position: relative;\r\n}\r\n\r\n@media (max-width: 1024px) {\r\n  .img1 {\r\n    top: 10px;\r\n  }\r\n}\r\n/* @media (max-width: 500px) {\r\n  .footer-btn {\r\n    display: flex;\r\n    position: absolute;\r\n    z-index: 89;\r\n    right: auto;\r\n    bottom: auto;\r\n  }\r\n  .selec {\r\n    width: auto !important;\r\n    right: 20px !important;\r\n  }\r\n} */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
